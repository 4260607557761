const userStore = JSON.parse(localStorage.getItem('user'));
const initialState = userStore ? {status: {loggedIn: true}, user: userStore} : {status: {loggedIn: false}, user: null};

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {

  },
  mutations: {
    
  }
};