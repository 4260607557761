import Vue from 'vue';
import App from './App.vue';
import router from "./router";
import store from "./store";
import Vuex from "vuex";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/assets/css/kamezoko.css';
import { BootstrapVue, IconsPlugin, TooltipPlugin } from 'bootstrap-vue';
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt, faLock, faChartLine, faFileWord, faTimes, 
  faArrowDown, faChevronDown, faBookmark, faCaretRight, faTrash, faEdit,
} from "@fortawesome/free-solid-svg-icons";
// https://github.com/FortAwesome/Font-Awesome/tree/master/js-packages/%40fortawesome/free-brands-svg-icons
import {
  faFacebook, faFacebookF, faGooglePlusG, faTwitter, faLinkedin, faGithub, faGooglePlay, faAppStore,
} from '@fortawesome/free-brands-svg-icons';
library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt, faLock, faChartLine, faFacebook, faFacebookF, 
  faLinkedin, faGooglePlusG, faTwitter, faFileWord, faTimes, faArrowDown, faChevronDown, faBookmark, faCaretRight, 
  faTrash, faEdit, faGithub, faGooglePlay, faAppStore);


Vue.config.productionTip = false

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(TooltipPlugin);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(Vuex);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
